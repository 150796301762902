import { useRouter } from 'next/router';
import { useMemo } from 'react';

type RouteType = 'supplier' | 'customer';

interface RouteTypeInfo {
    className: string;
    url: string;
    type: RouteType;
    navigationImage: string;
    routes: any[];
}

const useCabinet = (): RouteTypeInfo => {
    const router = useRouter();

    return useMemo(() => {
        const path = router.asPath;
        const isSupplier = path.includes('/supplier');
        const isCustomer = path.includes('/customer');

        if (isSupplier) {
            return {
                className: 'supplier',
                url: '/supplier',
                type: 'supplier',
                navigationImage: 'supplier',
                routes: [],
            };
        } else if (isCustomer) {
            return {
                className: 'customer',
                url: '/customer',
                type: 'customer',
                navigationImage: 'customer',
                routes: [],
            };
        } else {
            return {
                className: '',
                url: '',
                type: 'customer',
                navigationImage: '',
                routes: [],
            };
        }
    }, [router.asPath]);
};

export default useCabinet;
