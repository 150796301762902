import { useMutation } from '@tanstack/react-query';
import api from '@/utils/fetch';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from '../utils/constants';

export function useRefreshToken() {
    const refreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);
    const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);
    return useMutation({
        mutationFn: async () => {
            if (!refreshToken) {
                throw new Error('No refresh token available');
            }
            return api.post(
                '/refresh?refresh_token=' + refreshToken,
                undefined,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        },
    });
}
