import Link from 'next/link';
import Image from 'next/image';
import { FC, useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import { useAuth } from '@/features/auth/context/auth';
import useIsMobile from '@/hooks/use-is-mobile';
import { Menu, X, ArrowLeft, ChevronRight, LogOut } from 'lucide-react';
import LanguageSwitcher from '@/components/language-switcher';
import { publicMenuRoutes } from '@/routes/public.routes';
import { useGetCustomerProfile } from '@/features/customer/api/use-get-customer-profile';
import Avatar from '@/features/files/components/avatar';
import { useGetSupplierProfile } from '@/features/supplier/api/use-get-supplier-profile';
import useCabinet from '@/hooks/use-cabinet';
import { supplierCabinetRoutes } from '@/routes/supplier-cabinet.routes';
import { customerCabinetRoutes } from '@/routes/customer-cabinet.routes';
import SupplierNavLink from '@/features/supplier/components/supplier-nav-link';
import CustomerNavLink from '@/features/customer/components/customer-nav-link';
import CabinetHeaderAvatarDesktop from '@/components/cabinet-header-avatar-desktop-menu';
import { getCustomerName } from '@/features/profile/utils/helpers';
import { setUserProfileType } from '@/features/auth/utils/helpers';
import { useRouter } from 'next/router';
import Notifications from '@/features/notifications';
const CabinetHeader: FC<{
  title: string;
}> = ({
  title
}) => {
  const {
    t,
    i18n
  } = useTranslation();
  const isMobile = useIsMobile();
  const [isOpenBaseMenu, setIsOpenBaseMenu] = useState(false);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const customerProfile = useGetCustomerProfile();
  const supplierProfile = useGetSupplierProfile();
  const cabinet = useCabinet();
  const {
    signOut,
    sessionData,
    checkLoginStatus
  } = useAuth();
  const baseMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const router = useRouter();
  const switchToCustomer = () => {
    setUserProfileType('CUSTOMER');
    checkLoginStatus();
    router.push('/customer/dashboard');
  };
  const switchToSupplier = () => {
    setUserProfileType('SUPPLIER');
    checkLoginStatus();
    if (sessionData?.user_supplier_uuid) {
      router.push('/supplier/dashboard');
    } else {
      router.push('/supplier/workspace/create');
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (baseMenuRef.current && !(baseMenuRef.current as any).contains(event.target)) {
        setIsOpenBaseMenu(false);
      }
      if (userMenuRef.current && !(userMenuRef.current as any).contains(event.target)) {
        setIsOpenUserMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  if (!customerProfile?.data?.data) {
    return;
  }
  let phone = supplierProfile?.data?.data.phone;
  const {
    avatar_sizes
  } = customerProfile.data.data;
  const customerAvatar = avatar_sizes?.length ? avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || avatar_sizes[0]?.url : null;
  const supplierAvatar = supplierProfile.data?.data?.avatar_sizes?.length ? supplierProfile?.data?.data?.avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || supplierProfile?.data?.data.avatar_sizes[0].url : null;
  const avatar = cabinet.type === 'customer' ? customerAvatar : supplierAvatar;
  const customerName = getCustomerName(customerProfile.data.data);
  const name = i18n.language === 'bg' ? customerName.cyrilicName ? customerName.cyrilicName : customerName.latinName : customerName.latinName;
  const supplierName = supplierProfile.data?.data.name ? supplierProfile.data?.data.name : supplierProfile.data?.data.email;
  if (customerProfile.data.data.phone) {
    phone = customerProfile.data.data.phone;
  }
  return isMobile ? <>
            <header className="h-20 cabinet-header drop-shadow-xl flex items-center justify-around gap-2 relative z-10">
                <div className="w-full max-w-screen-2xl flex justify-between items-center px-6">
                    <button onClick={() => setIsOpenBaseMenu(!isOpenBaseMenu)} className="text-text z-[1000]">
                        {isOpenBaseMenu ? <X size={28} /> : <Menu size={28} />}
                    </button>
                    <div className="relative w-full flex items-center justify-around">
                        <Link href="/" data-sentry-element="Link" data-sentry-source-file="cabinet-header.tsx">
                            <Image src="/images/header/icon.svg" alt="ofertirai.me" width={231} height={46} className="w-full max-w-[231px]" priority data-sentry-element="Image" data-sentry-source-file="cabinet-header.tsx" />
                        </Link>
                    </div>
                    <div className={`flex items-center ${!avatar ? 'p-[4px]' : 'p-0'} min-width bg-white rounded-full gap-4 w-fit cursor-pointer`} onClick={() => setIsOpenUserMenu(!isOpenUserMenu)}>
                        <div className="rounded-full bg-white w-[30px] custom-avatar-data">
                            <Avatar src={avatar} size={24} autoSize={true} data-sentry-element="Avatar" data-sentry-source-file="cabinet-header.tsx" />
                        </div>
                    </div>
                </div>
            </header>
            {isOpenBaseMenu || isOpenUserMenu ? <div className="fixed inset-0 bg-black bg-opacity-50 z-[998]" onClick={() => setIsOpenBaseMenu(false)} /> : ''}
            <div ref={baseMenuRef} className={`fixed top-0 left-0 w-[80vw] sm:w-[40vw] h-screen flex flex-col justify-between pb-4 background-radient-color overflow-x-hidden transition-transform duration-300 ease-in-out z-[999] ${isOpenBaseMenu ? 'translate-x-0' : '-translate-x-full'}`}>
                <nav className="flex flex-col items-start p-4">
                    <span className="text-text mb-10 cursor-pointer" onClick={() => {
          setIsOpenBaseMenu(false);
        }}>
                        <ArrowLeft size={24} data-sentry-element="ArrowLeft" data-sentry-source-file="cabinet-header.tsx" />
                    </span>
                    {publicMenuRoutes.map((route: any) => <Link key={route.id} href={route.url} className={`text-lg uppercase mb-2 no-underline py-2 text-text border-border border-b-2 flex items-center justify-between w-full ${route?.disabled ? 'pointer-events-none opacity-50' : ''}`} onClick={() => setIsOpenBaseMenu(false)} suppressHydrationWarning>
                            {t(route?.name || '')}
                            <ChevronRight size={24} className="text-text" />
                        </Link>)}
                </nav>
                {sessionData && <div className="px-4">
                        <Link href="#" className="text-lg uppercase mb-2 no-underline py-2 text-text border-border border-b-2 flex items-center justify-between w-full" onClick={signOut} suppressHydrationWarning>
                            <LogOut size={24} className="text-text" />
                            {i18n.language === 'en' ? 'Exit' : 'Изход'}
                            <ChevronRight size={24} className="text-text" />
                        </Link>
                    </div>}
                <div className="p-4">
                    <LanguageSwitcher data-sentry-element="LanguageSwitcher" data-sentry-source-file="cabinet-header.tsx" />
                </div>
            </div>
            <div ref={userMenuRef} className={`fixed top-0 right-0 w-[80vw] sm:w-[40vw] h-screen flex flex-col justify-between pb-4 background-radient-color overflow-x-hidden transition-transform duration-300 ease-in-out z-[999] ${isOpenUserMenu ? 'translate-x-0' : 'translate-x-full'}`}>
                <nav className="flex flex-col items-end pt-8 px-10">
                    <span className="text-text mb-10 cursor-pointer" onClick={() => {
          setIsOpenUserMenu(false);
        }}>
                        <ArrowRight size={24} data-sentry-element="ArrowRight" data-sentry-source-file="cabinet-header.tsx" />
                    </span>
                    <div className="w-full pl-4 border-text py-4 border-b-[2px]">
                        <div className="flex w-full gap-4 items-end">
                            <div className="h-[60px] w-[60px] min-w-[60px]">
                                <Avatar src={avatar} size={24} autoSize={true} data-sentry-element="Avatar" data-sentry-source-file="cabinet-header.tsx" />
                            </div>
                            <div>
                                <div className="text-text text-xl font-normal">
                                    {name}
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full gap-4 mt-2 items-end">
                            <div className="text-text pl-2 text-xl font-normal">
                                User
                            </div>
                            <div className="text-text text-xl font-normal">
                                {phone}
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-4">
                        {cabinet.type === 'supplier' ? supplierCabinetRoutes.map(link => <span key={link.name} onClick={() => {
            if (!link?.children) {
              setIsOpenUserMenu(false);
            }
          }}>
                                      <SupplierNavLink link={link} sessionSupplierId={sessionData?.user_supplier_uuid} />
                                  </span>) : ''}
                        {cabinet.type === 'customer' ? customerCabinetRoutes.map(link => <span key={link.name} onClick={() => {
            if (!link?.children) {
              setIsOpenUserMenu(false);
            }
          }}>
                                      <CustomerNavLink link={link} />
                                  </span>) : ''}
                        {cabinet.type === 'customer' ? <span className="text-base mb-2 p-4 rounded-lg cursor-pointer bg-transparent text-text flex items-center gap-4 w-full hover:bg-green-400 transition-all" onClick={switchToSupplier} suppressHydrationWarning>
                                <LogOut size={24} className="text-text" />
                                {i18n.language === 'en' ? 'Supplier' : 'Доставчик'}
                            </span> : ''}
                        {cabinet.type === 'supplier' ? <span className="text-base mb-2 p-4 rounded-lg cursor-pointer bg-transparent text-text flex items-center gap-4 w-full hover:bg-amber-200 transition-all" onClick={switchToCustomer} suppressHydrationWarning>
                                <LogOut size={24} className="text-text" />
                                {i18n.language === 'en' ? 'Customer' : 'Клиент'}
                            </span> : ''}
                    </div>
                </nav>
            </div>
        </> : <header className="h-20 cabinet-header drop-shadow-xl flex items-center justify-around gap-2" data-sentry-component="CabinetHeader" data-sentry-source-file="cabinet-header.tsx">
            <div className="w-full max-w-screen-2xl flex justify-between items-center px-6 2xl:px-6">
                <div className="flex gap-16 items-center">
                    <Link href="/" data-sentry-element="Link" data-sentry-source-file="cabinet-header.tsx">
                        <Image src="/images/header/cabinet-logo.svg" alt="ofertirai.me" width={231} height={46} className="md:w-[165px] xl:w-[219px] transition-all" priority data-sentry-element="Image" data-sentry-source-file="cabinet-header.tsx" />
                    </Link>
                    <div className="text-left">
                        <h1 className="text-gray-700 text-xl font-base">
                            {t(title)}
                        </h1>
                    </div>
                </div>
                <div className="flex gap-x-6 items-center">
                    <Notifications customerType={cabinet.type} data-sentry-element="Notifications" data-sentry-source-file="cabinet-header.tsx" />
                    <CabinetHeaderAvatarDesktop avatar={avatar} cyrilicName={customerName.cyrilicName || customerName.email} latinName={customerName.latinName || customerName.email} supplierName={supplierName} data-sentry-element="CabinetHeaderAvatarDesktop" data-sentry-source-file="cabinet-header.tsx" />
                </div>
            </div>
        </header>;
};
export default CabinetHeader;