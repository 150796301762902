import React, { useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import useAuth from '@/hooks/use-auth-session';
interface NavLink {
  name: string;
  icon: JSX.Element;
  path: string;
  disabled?: boolean;
  children?: NavLink[];
}
const SupplierNavLink: FC<{
  link: NavLink;
  sessionSupplierId: string | undefined | null;
  level?: number;
}> = ({
  link,
  sessionSupplierId,
  level = 0
}) => {
  const {
    t
  } = useTranslation();
  const {
    sessionData
  } = useAuth();
  const router = useRouter();
  const currentPath = router.asPath;
  const href = link.path;
  const isDisabled = link.disabled || !sessionData?.user_supplier_uuid;
  const isParentLinkActive = currentPath?.includes(link.path) || link.children && link.children.some(child => currentPath === child.path);
  const isActive = level > 0 ? currentPath === link.path : isParentLinkActive;
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = (e: React.MouseEvent) => {
    if (link.children && !isDisabled) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };
  const renderLink = () => <span className={`${level ? 'w-[90%] lg:w-[257px]' : 'w-full lg:w-[275px]'} block p-4 mb-2 rounded-lg flex items-center justify-between transition-all cursor-pointer ${isActive ? 'bg-supplier' : 'bg-transparent hover:bg-amber-200'} ${isDisabled ? 'opacity-75 pointer-events-none' : ''}`} style={{
    paddingLeft: `${level * 16 + 16}px`
  }} data-sentry-component="renderLink" data-sentry-source-file="supplier-nav-link.tsx">
            <span className="flex items-center gap-2">
                {link.icon}
                <span className="block ml-2 text-text text-base" suppressHydrationWarning>
                    {t(link.name)}
                </span>
            </span>
            {link.children && <span className="text-text">
                    {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </span>}
        </span>;
  return <div data-sentry-component="SupplierNavLink" data-sentry-source-file="supplier-nav-link.tsx">
            {link.children ? <div onClick={handleClick} className="cursor-pointer">
                    {renderLink()}
                </div> : <Link href={isDisabled ? '#' : href} aria-disabled={isDisabled}>
                    {renderLink()}
                </Link>}
            {link.children && isOpen && <div className="ml-4 ">
                    {link.children.map(childLink => <SupplierNavLink key={childLink.name} link={childLink} sessionSupplierId={sessionSupplierId} level={level + 1} />)}
                </div>}
        </div>;
};
export default SupplierNavLink;