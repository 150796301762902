import Cookies from 'js-cookie';
import {
    COOKIE_PROFILE_TYPE,
    COOKIE_SESSION_DATA,
    COOKIE_REFRESH_TOKEN,
    COOKIE_ACCESS_TOKEN,
    COOKIE_REMEMBER_USER_PROFILE,
    COOKIE_NEW_REGISTERED_SUPPLIER_ID,
} from './constants';
import { LoginResponse, ProfileTypes, SessionData } from '../dtos/auth.type';

export function setUserData(data: LoginResponse) {
    Cookies.set(COOKIE_SESSION_DATA, data.session_data, {
        expires: 1,
        path: '/',
        secure: true,
        sameSite: 'strict',
    });
    Cookies.set(COOKIE_REFRESH_TOKEN, data.refresh_token, {
        expires: 7,
        path: '/',
        secure: true,
        sameSite: 'strict',
    });
    Cookies.set(COOKIE_ACCESS_TOKEN, data.token, {
        expires: 1,
        path: '/',
        secure: true,
        sameSite: 'strict',
    });
}

export function setUserProfileType(profileType: ProfileTypes): void {
    if (!profileType) {
        console.error('Invalid profile type');
        return;
    }
    const sessionData = getSessionData();
    if (sessionData) {
        const isCookie = Cookies.get(COOKIE_PROFILE_TYPE);
        if (isCookie) {
            Cookies.remove(COOKIE_PROFILE_TYPE);
        }
        const expiration = sessionData.expiration_time + '000';

        Cookies.set(COOKIE_PROFILE_TYPE, profileType, {
            expires: Number(expiration),
        });
    }
}

export function getSessionData(): SessionData | null {
    const sessionDataCookie = Cookies.get(COOKIE_SESSION_DATA);
    const newRegisterSupplierId = Cookies.get(
        COOKIE_NEW_REGISTERED_SUPPLIER_ID
    );
    if (!sessionDataCookie) {
        return null;
    }
    const sessionData = atob(sessionDataCookie);

    const sessionDataObject = JSON.parse(sessionData);

    return {
        ...sessionDataObject,
        user_supplier_uuid:
            sessionDataObject.user_supplier_uuid ?? newRegisterSupplierId,
    };
}

export function getProfileType(): ProfileTypes {
    const cookie = Cookies.get(COOKIE_PROFILE_TYPE);
    if (
        typeof cookie === 'string' &&
        cookie !== 'SUPPLIER' &&
        cookie !== 'CUSTOMER'
    ) {
        throw new Error('Invalid profile type');
    }

    return cookie;
}

export function setRememberMeToken(email: string, password: string) {
    const data = {
        ask: btoa(email),
        get: btoa(password),
    };
    Cookies.set(COOKIE_REMEMBER_USER_PROFILE, btoa(JSON.stringify(data)), {
        expires: 365,
    });
}

export function getRememberMe() {
    const token = Cookies.get(COOKIE_REMEMBER_USER_PROFILE);
    if (!token) {
        return;
    }
    const data: { ask: string; get: string } = JSON.parse(atob(token));

    return {
        email: atob(data.ask),
        password: atob(data.get),
    };
}
