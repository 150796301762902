import { Briefcase, BriefcaseBusiness, ChartPie, House, LayoutDashboard, Settings, Store } from 'lucide-react';
export const customerCabinetRoutes = [{
  name: 'CUSTOMER_MENU.DASHBOARD',
  icon: <House size={24} stroke="#545756" />,
  path: '/customer/dashboard'
}, {
  name: 'CUSTOMER_MENU.CUSTOMER_WORKSPACE',
  icon: <LayoutDashboard size={24} stroke="#545756" />,
  path: '/customer/workspace',
  children: [{
    name: 'CUSTOMER_MENU.TASK',
    icon: <BriefcaseBusiness size={24} stroke="#545756" />,
    path: '/customer/workspace/orders'
  }, {
    name: 'CUSTOMER_MENU.TASK_HISTORY',
    icon: <Store size={24} stroke="#545756" />,
    path: '/customer/workspace/orders/history'
  }]
}, {
  name: 'CUSTOMER_MENU.PERSONAL_INFORMATION',
  icon: <ChartPie size={24} stroke="#545756" />,
  path: '/customer/personal-information'
},
// {
//   name: "CUSTOMER_MENU.HELP",
//   icon: <Briefcase size={24} stroke="#545756" />,
//   path: "/customer/help",
//   disabled: true,
// },
{
  name: 'CUSTOMER_MENU.SETTINGS',
  icon: <Settings size={24} stroke="#545756" />,
  path: '/customer/settings',
  disabled: false
}];