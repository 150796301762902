import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { publicMenuRoutes } from '@/routes/public.routes';
import { Menu, X, ChevronRight, UserRound, ArrowLeft } from 'lucide-react';
import useIsMobile from '@/hooks/use-is-mobile';
import { useTranslation } from 'react-i18next';
import logoSrc from '@public/images/logo.png';
import { LogOut } from 'lucide-react';
import { useAuth } from '@/features/auth/context/auth';
import { useGetCustomerProfile } from '@/features/customer/api/use-get-customer-profile';
const Image = dynamic(() => import('next/image'), {
  ssr: false
});
const LanguageSwitcher = dynamic(() => import('@/components/language-switcher'), {
  ssr: false
});
const Avatar = dynamic(() => import('@/features/files/components/avatar'), {
  ssr: false
});
const PublicHeader = () => {
  const {
    status,
    profileType,
    signOut,
    checkLoginStatus
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const {
    t
  } = useTranslation();
  const profile = useGetCustomerProfile();
  const avatar_sizes = profile?.data?.data.avatar_sizes;
  const userAvatar = avatar_sizes?.length ? avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || avatar_sizes[0]?.url : null;
  const menuRef = useRef(null);
  useEffect(() => {
    const intervalId = setInterval(checkLoginStatus, 60000);
    return () => clearInterval(intervalId);
  }, [checkLoginStatus]);
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (menuRef.current && !(menuRef?.current as any).contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  /**
   * Note, there is difference in the colors of language switcher and
   * image and the links up top. This is also in figma which is strange.
   * @todo: add the functionality of the language switcher and icons later */
  return isMobile ? <header className="h-20 bg-white flex items-center justify-around gap-2 border-border border-b-[1px]" data-sentry-component="PublicHeader" data-sentry-source-file="public-header.tsx">
            <div className="relative w-full max-w-screen-2xl flex justify-between items-center px-6">
                <button onClick={() => setIsOpen(!isOpen)} className="text-text" aria-label="Menu">
                    {isOpen ? <X size={28} /> : <Menu size={28} />}
                </button>
                <div ref={menuRef} className="relative w-full flex items-center justify-around">
                    <Link href="/" data-sentry-element="Link" data-sentry-source-file="public-header.tsx">
                        <Image src="/images/header/icon.svg" alt="ofertirai.me" width={231} height={46} className="w-full max-w-[231px]" priority data-sentry-element="Image" data-sentry-source-file="public-header.tsx" />
                    </Link>
                </div>
                <div className="">
                    {profileType === 'CUSTOMER' && <Link href="/customer/dashboard">
                            {Boolean(userAvatar) ? <div className="w-[40px] h-[40px] border-emerald-600 border-2 rounded-full">
                                    <Avatar src={userAvatar} size={48} autoSize={true} />
                                </div> : <UserRound size={28} stroke="#2e7d32" />}
                        </Link>}
                    {profileType === 'SUPPLIER' && <Link href="/supplier/dashboard">
                            {Boolean(userAvatar) ? <div className="w-[40px] h-[40px] border-amber-400 border-2 rounded-full">
                                    <Avatar src={userAvatar} size={48} autoSize={true} />
                                </div> : <UserRound size={28} stroke="#f2cb0c" />}
                        </Link>}
                    {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && !status && <Link href="/login" className="text-text">
                                <UserRound size={28} />
                            </Link>}
                    {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && status && <Link href="/login" className="text-text">
                                <UserRound size={28} />
                            </Link>}
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[80vw] sm:w-[40vw] h-full flex flex-col justify-between pb-4 background-radient-color overflow-x-hidden transition-transform duration-300 ease-in-out z-10 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                <nav className="flex flex-col items-start p-4">
                    <span className="text-text mb-10 cursor-pointer" onClick={() => {
          setIsOpen(false);
        }}>
                        <ArrowLeft size={24} data-sentry-element="ArrowLeft" data-sentry-source-file="public-header.tsx" />
                    </span>
                    {publicMenuRoutes.map((route: any) => <Link key={route.id} href={route.url} className={`text-lg uppercase mb-2 no-underline py-2 text-border border-border border-b-2 flex items-center justify-between w-full ${route.disabled ? 'pointer-events-none opacity-50' : ''}`} onClick={() => setIsOpen(false)} suppressHydrationWarning>
                            {t(route?.name || '')}
                            <ChevronRight size={24} className="text-text" />
                        </Link>)}
                </nav>
                {status && <div className="px-4">
                        <Link href="#" className="text-lg uppercase mb-2 no-underline py-2 text-text border-border border-b-2 flex items-center justify-between w-full" onClick={signOut} suppressHydrationWarning>
                            <LogOut size={24} className="text-text" />
                            {t('EXIT')}
                            <ChevronRight size={24} className="text-text" />
                        </Link>
                    </div>}
                <div className="p-4">
                    <LanguageSwitcher data-sentry-element="LanguageSwitcher" data-sentry-source-file="public-header.tsx" />
                </div>
            </div>
        </header> : <header className="h-20 bg-background flex items-center justify-around gap-2" data-sentry-component="PublicHeader" data-sentry-source-file="public-header.tsx">
            <div className="w-full max-w-screen-2xl flex justify-between items-center px-6 2xl:px-6">
                <Link href="/" data-sentry-element="Link" data-sentry-source-file="public-header.tsx">
                    <Image src="/images/header/icon.svg" alt="ofertirai.me" width={231} height={46} className="md:w-[165px] xl:w-[200.42px] transition-all" priority data-sentry-element="Image" data-sentry-source-file="public-header.tsx" />
                </Link>
                <nav className="hidden md:flex items-center md:gap-2 xl:gap-4 2xl:gap-8">
                    {publicMenuRoutes.map((route: any) => <Link key={route.id} href={route.url} className={`text-lg bg-white no-underline uppercase md:px-2 lg:px-4 py-[6px] border-text border-[1px] rounded-2xl ${route?.disabled ? 'pointer-events-none opacity-50' : ''}`} suppressHydrationWarning>
                            {t(route?.name || '')}
                        </Link>)}
                    <LanguageSwitcher data-sentry-element="LanguageSwitcher" data-sentry-source-file="public-header.tsx" />
                    {profileType === 'CUSTOMER' && <Link href="/customer/dashboard">
                            {Boolean(userAvatar) ? <div className="w-[40px] h-[40px] border-emerald-600 border-2 rounded-full">
                                    <Avatar src={userAvatar} size={48} autoSize={true} />
                                </div> : <Image src={logoSrc} alt={'account'} width={24} height={24} className={`rounded-full bg-white border-emerald-600 border-[3px] w-[38px] p-[6px]`} />}
                        </Link>}
                    {profileType === 'SUPPLIER' && <Link href="/supplier/dashboard">
                            {Boolean(userAvatar) ? <div className="w-[40px] h-[40px] border-amber-400 border-2 rounded-full">
                                    <Avatar src={userAvatar} size={48} autoSize={true} />
                                </div> : <Image src={logoSrc} alt={'account'} width={24} height={24} className={`rounded-full bg-white border-amber-400 border-[3px] w-[38px] p-[6px]`} />}
                        </Link>}
                    {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && !status && <Link href="/login">
                                <Image src={logoSrc} alt={'account'} width={38} height={38} className={`rounded-full bg-white border-black border-[1px] w-[38px] p-[6px]`} />
                            </Link>}
                    {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && status && <Link href="/login">
                                <Image src={logoSrc} alt={'account'} width={38} height={38} className={`rounded-full bg-white border-black border-[1px] w-[38px] p-[6px]`} />
                            </Link>}
                </nav>
            </div>
        </header>;
};
export default dynamic(() => Promise.resolve(PublicHeader), {
  ssr: false
});