import { FC } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Avatar from '@/features/files/components/avatar';
import { ChevronDown } from 'lucide-react';
import { useAuth } from '@/features/auth/context/auth';
import { setUserProfileType } from '@/features/auth/utils/helpers';
import { useRouter } from 'next/router';
import useCabinet from '@/hooks/use-cabinet';
import { useTranslation } from 'react-i18next';
interface CabinetAvatarDesktopProps {
  avatar: string | null;
  cyrilicName: string;
  latinName: string;
  supplierName: string | undefined;
}
const CabinetHeaderAvatarDesktop: FC<CabinetAvatarDesktopProps> = ({
  avatar,
  cyrilicName,
  latinName,
  supplierName
}) => {
  const {
    signOut,
    checkLoginStatus,
    sessionData
  } = useAuth();
  const cabinet = useCabinet();
  const router = useRouter();
  const {
    i18n
  } = useTranslation();
  const type = cabinet.type;
  const language = i18n.language;
  const switchToCustomer = () => {
    setUserProfileType('CUSTOMER');
    checkLoginStatus();
    router.push('/customer/dashboard');
  };
  const switchToSupplier = () => {
    setUserProfileType('SUPPLIER');
    checkLoginStatus();
    if (sessionData?.user_supplier_uuid) {
      router.push('/supplier/dashboard');
    } else {
      router.push('/supplier/workspace/create');
    }
  };
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="CabinetHeaderAvatarDesktop" data-sentry-source-file="index.tsx">
            <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="index.tsx">
                <div className="h-[40px] flex items-center px-[4px] min-width bg-white rounded-lg gap-4 w-fit cursor-pointer">
                    <div className="w-[34px] rounded-full cabinet-header custom-avatar-data">
                        <Avatar src={avatar} size={24} autoSize={true} data-sentry-element="Avatar" data-sentry-source-file="index.tsx" />
                    </div>
                    <div className="flex gap-4">
                        <span className="text-text text-base font-light">
                            {type === 'supplier' ? supplierName : language === 'bg' ? cyrilicName : latinName}
                        </span>{' '}
                        <ChevronDown size={24} data-sentry-element="ChevronDown" data-sentry-source-file="index.tsx" />
                    </div>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full bg-white outline-none border-none" data-sentry-element="DropdownMenuContent" data-sentry-source-file="index.tsx">
                {type === 'supplier' ? <DropdownMenuItem className="cursor-pointer bg-white outline-none border-none" onClick={switchToCustomer}>
                        {language === 'en' ? 'Switch to customer' : "Премини към 'Клиент'"}
                    </DropdownMenuItem> : <DropdownMenuItem className="cursor-pointer bg-white outline-none border-none" onClick={switchToSupplier}>
                        {language === 'en' ? 'Switch to supplier' : "Премини към 'Доставчик'"}
                    </DropdownMenuItem>}
                <DropdownMenuItem className="cursor-pointer bg-white outline-none border-none" onClick={signOut} data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    {language === 'en' ? 'Logout' : 'Изход'}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>;
};
export default CabinetHeaderAvatarDesktop;