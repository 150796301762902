'use client';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
const ReactQueryProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [queryClient] = useState(() => new QueryClient());
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="ReactQueryProvider" data-sentry-source-file="react-query-provider.tsx">
            {children}
        </QueryClientProvider>;
};
export default ReactQueryProvider;