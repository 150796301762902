import React, { createContext, useState, useContext, useCallback } from 'react';
interface AvatarContextType {
  avatarUrl: string | null;
  updateAvatar: (newUrl: string) => void;
}
const AvatarContext = createContext<AvatarContextType | undefined>(undefined);
export const AvatarProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const updateAvatar = useCallback((newUrl: string) => {
    setAvatarUrl(newUrl);
  }, []);
  return <AvatarContext.Provider value={{
    avatarUrl,
    updateAvatar
  }} data-sentry-element="unknown" data-sentry-component="AvatarProvider" data-sentry-source-file="avatar.tsx">
            {children}
        </AvatarContext.Provider>;
};
export const useAvatar = () => {
  const context = useContext(AvatarContext);
  if (context === undefined) {
    throw new Error('useAvatar must be used within an AvatarProvider');
  }
  return context;
};