import { FC, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ChevronDown, ChevronUp } from 'lucide-react';
interface NavLink {
  name: string;
  icon: JSX.Element;
  path: string;
  disabled?: boolean;
  children?: NavLink[];
}
const CustomerNavLink: FC<{
  link: NavLink;
  level?: number;
}> = ({
  link,
  level = 0
}) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const currentPath = router.asPath;
  const isParentLinkActive = currentPath?.includes(link.path) || link.children && link.children.some(child => currentPath === child.path);
  const isActive = level > 0 ? currentPath === link.path : isParentLinkActive;
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = (e: React.MouseEvent) => {
    if (link.children) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };
  const renderLink = () => <span className={`${level ? 'w-[90%] lg:w-[257px]' : 'w-full lg:w-[275px]'} block p-4 mb-2 rounded-lg flex items-center justify-between transition-all cursor-pointer ${isActive ? 'bg-customer' : 'bg-transparent hover:bg-green-400'} ${link.disabled ? 'opacity-75 pointer-events-none' : ''}`} style={{
    paddingLeft: `${level * 16 + 16}px`
  }} data-sentry-component="renderLink" data-sentry-source-file="customer-nav-link.tsx">
            <span className="flex items-center gap-2">
                {link.icon}
                <span className={`block ml-2 text-text text-base ${isActive ? 'text-white' : ''}`} suppressHydrationWarning>
                    {t(link.name)}
                </span>
            </span>
            {link.children && <span className="text-text">
                    {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </span>}
        </span>;
  return <div data-sentry-component="CustomerNavLink" data-sentry-source-file="customer-nav-link.tsx">
            {link.children ? <div onClick={handleClick} className="cursor-pointer">
                    {renderLink()}
                </div> : <Link href={link.path} aria-disabled={link.disabled}>
                    {renderLink()}
                </Link>}
            {link.children && isOpen && <div className="ml-4 ">
                    {link.children.map(childLink => <CustomerNavLink key={childLink.name} link={childLink} level={level + 1} />)}
                </div>}
        </div>
  // <Link
  //   href={link.path}
  //   aria-disabled={link.disabled}
  //   className={`${link.disabled ? "opacity-75 pointer-events-none" : ""}`}
  // >
  //   <span
  //     className={`w-full lg:w-[275px] block p-4 mb-2 rounded-lg flex gap-2 items-center transition-all cursor-pointer ${
  //       currentPath?.includes(link.path)
  //         ? "bg-customer"
  //         : "bg-transparent hover:bg-green-400"
  //     }`}
  //   >
  //     {link.icon}
  //     <span
  //       className={`block ml-2 ${
  //         currentPath?.includes(link.path) ? "text-white" : "text-text"
  //       } text-base`}
  //       suppressHydrationWarning
  //     >
  //       {t(link.name)}
  //     </span>
  //   </span>
  // </Link>
  ;
};
export default CustomerNavLink;