import { FC, ReactNode } from 'react';
import PublicHeader from '@/containers/public-header';
import Footer from '@/containers/footer';
import CookiePolicyModal from '@/components/cookie-policy-modal';
const PublicLayout: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  return <>
            <PublicHeader data-sentry-element="PublicHeader" data-sentry-source-file="public.tsx" />
            <main className="main-layout">{children}</main>
            <CookiePolicyModal data-sentry-element="CookiePolicyModal" data-sentry-source-file="public.tsx" />
            <Footer data-sentry-element="Footer" data-sentry-source-file="public.tsx" />
        </>;
};
export default PublicLayout;