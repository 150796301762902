import { useQuery } from '@tanstack/react-query';
import { CustomerQueryKeys } from './query-keys';
import { useRouter } from 'next/router';
import api from '@/utils/fetch';
import { useAuth } from '@/features/auth/context/auth';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN } from '@/features/auth/utils/constants';
import { CustomerProfileResponse } from '../dtos/api.types';
import { FilesQueryKeys } from '@/features/files/api/query-keys';
export function useGetCustomerProfile() {
  const {
    locale
  } = useRouter();
  const {
    sessionData
  } = useAuth();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);
  return useQuery<{
    data: CustomerProfileResponse;
  }, Error>({
    queryKey: [CustomerQueryKeys.Profile, locale, FilesQueryKeys.Avatar],
    queryFn: () => api.get('/customer/' + sessionData?.user_customer_uuid, {
      headers: {
        'Accept-Language': locale,
        Authorization: `Bearer ${token}`
      }
    }),
    enabled: !!token && sessionData?.user_customer_uuid !== undefined
  });
}