import { useState, useEffect } from 'react';
import closeIcon from '@public/images/notifications/close.svg';
import successIcon from '@public/images/notifications/status/success.svg';
import warningIcon from '@public/images/notifications/status/warning.svg';
import failIcon from '@public/images/notifications/status/fail.svg';
import Image from 'next/image';
import { NotificationType } from '../dtos/types';
type ComponentProps = {
  uuid: string;
  type: NotificationType;
  title: string;
  description: string;
  readAll: boolean;
  onReadNotification: (notificationUuid: string) => void;
};
function getNotificationIcon(type: NotificationType) {
  let icon = '';
  switch (type) {
    case 'success':
      icon = successIcon;
      break;
    case 'warning':
      icon = warningIcon;
      break;
    case 'fail':
      icon = failIcon;
      break;
  }
  return icon;
}
const NotificationCard: React.FC<ComponentProps> = ({
  uuid,
  type,
  title,
  description,
  readAll,
  onReadNotification
}: ComponentProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (readAll) {
      setIsClosing(true);
    }
  }, [readAll]);
  const handleClose = () => {
    setIsClosing(true);
  };
  const handleTransitionEnd = () => {
    if (isClosing) {
      setIsHidden(true);
      onReadNotification(uuid);
    }
  };
  if (isHidden) return null;
  const titleColor = `text-${type}`;
  const notificationIcon = getNotificationIcon(type);
  return <li className={`rounded-xs border p-4 flex gap-x-3 mb-3 transition-all duration-300 ${isClosing ? 'opacity-0 translate-x-10' : ''}`} style={isHidden ? {
    display: 'none'
  } : undefined} onTransitionEnd={handleTransitionEnd} data-sentry-component="NotificationCard" data-sentry-source-file="notification-card.tsx">
            <div>
                <Image src={notificationIcon} width={24} height={24} className="min-h-[24px] min-w-[24px]" alt="status" data-sentry-element="Image" data-sentry-source-file="notification-card.tsx" />
            </div>
            <div>
                <h5 className={titleColor}>{title}</h5>
                <div>{description}</div>
            </div>
            <div className="ml-auto">
                <Image src={closeIcon?.src} width={18} height={18} alt="close" className="cursor-pointer min-h-[18px] min-w-[18px]" onClick={handleClose} data-sentry-element="Image" data-sentry-source-file="notification-card.tsx" />
            </div>
        </li>;
};
export default NotificationCard;