import { Loader } from '../components/loader';
import React, { ReactNode } from 'react';
import useCabinet from '../hooks/use-cabinet';
import { useTranslation } from 'react-i18next';

/**
 * Wrap your component with this, pass the necessary props
 * and it will show a loader until the component is loaded
 */
const LoaderContainer = ({
  isLoading,
  message,
  children,
  size,
  align = 'center',
  className
}: {
  isLoading: boolean;
  message?: string;
  children: ReactNode;
  className?: string;
  align?: 'start' | 'center' | 'end' | null;
  size?: 'normal' | 'small';
}) => {
  const {
    t
  } = useTranslation();
  const cabinet = useCabinet();
  return <>
            {isLoading ? <div className={`flex items-${align} flex-col ${cabinet.type} ${size === 'small' ? 'small p-2' : 'normal p-4'} ${className}`}>
                    <Loader />
                    {message ? <div className="pt-8">{t(message)}</div> : ''}
                </div> : <>{children}</>}
        </>;
};
export default LoaderContainer;