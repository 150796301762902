import { useEffect, useState } from 'react';
import NotificationIcon from './components/notification-icon';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import notificationsApiClient from './api';
import LoaderContainer from '@/containers/loader';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import NotificationCard from './components/notification-card';
import styles from './index.module.css';
import closeIcon from '@public/images/notifications/close.svg';
import emptyIcon from '@public/images/notifications/empty.svg';
import { Logger } from '@/utils/logger';
import { useToast } from '@/hooks/use-toast';
type ComponentProps = {
  customerType: 'supplier' | 'customer';
};
const Notifications: React.FC<ComponentProps> = ({
  customerType
}: ComponentProps) => {
  const {
    t
  } = useTranslation();
  const [notificationTabOpen, setNotificationTabOpen] = useState<boolean>(false);
  const [readAll, setReadAll] = useState<boolean>(false);
  const {
    toast
  } = useToast();
  const {
    data,
    refetch,
    isFetching,
    isLoading
  } = notificationsApiClient.useGetNotifications();
  const readNotification = notificationsApiClient.useReadNotification();
  const notificationsResponseData = data?.data;
  const notificationsCount = customerType === 'supplier' ? notificationsResponseData?.stats.supplier_notify_count : notificationsResponseData?.stats.customer_notify_count;
  const newNotifications = notificationsResponseData?.notifications.filter(notification => customerType === 'supplier' ? !!notification.user_supplier : !!notification.user_customer);
  const handleReadNotification = (notificationUuid: string) => {
    readNotification.mutate(notificationUuid, {
      onSuccess: () => {
        refetch();
      },
      onError: error => {
        toast({
          title: 'An error occured.',
          description: error.message
        });
        refetch();
        Logger.captureException(error);
      }
    });
  };
  const notificationItems = newNotifications?.map(notification => {
    return <NotificationCard key={notification.uuid} uuid={notification.uuid} type="success" title={notification.title || 'Notification'} description={notification.description} readAll={readAll} onReadNotification={handleReadNotification} />;
  });
  const handleNotificationTabOpen = () => {
    setNotificationTabOpen(current => !current);
  };
  useEffect(() => {
    if (readAll) {
      setReadAll(false);
    }
  }, [readAll]);
  return <div id="notifications-menu" className="relative" data-sentry-component="Notifications" data-sentry-source-file="index.tsx">
            <DropdownMenu open={notificationTabOpen} onOpenChange={handleNotificationTabOpen} data-sentry-element="DropdownMenu" data-sentry-source-file="index.tsx">
                <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="index.tsx">
                    <div className="relative select-none">
                        <NotificationIcon data-sentry-element="NotificationIcon" data-sentry-source-file="index.tsx" />
                        <div className="font-semibold w-[21px] h-[21px] bg-[#F2CB0C] rounded-full absolute top-[-4px] right-[-4px] text-white text-xs cursor-pointer flex justify-center items-center">
                            {isFetching ? <span className="bg-white w-[8px] h-[8px] rounded-full" /> : <span className="mt-[2px] ml-[1px]">
                                    {notificationsCount}
                                </span>}
                        </div>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white rounded-sm min-w-[363px] w-[363px] mt-2 p-4 text-text" data-sentry-element="DropdownMenuContent" data-sentry-source-file="index.tsx">
                    <div className={`max-h-[500px] h-[500px] ${styles.scrollable} ${!!notificationsCount && notificationsCount > 1 ? 'pl-1' : ''}`}>
                        <div className="flex justify-between items-center">
                            <h4 className="text-lg">
                                {t('NOTIFICATIONS.TITLE')}
                            </h4>
                            <Image src={closeIcon?.src} width={18} height={18} alt="close" className="cursor-pointer" onClick={handleNotificationTabOpen} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        </div>
                        <LoaderContainer isLoading={isLoading} data-sentry-element="LoaderContainer" data-sentry-source-file="index.tsx">
                            {!!notificationsCount && notificationsCount > 0 ? <>
                                    {notificationsCount > 1 ? <div className="rounded-sm border p-4 my-2">
                                            <p className="text-base">
                                                {t('NOTIFICATIONS.TITLE')}
                                            </p>
                                            <p>
                                                {t('NOTIFICATIONS.NEW_NOTIFICATIONS_COUNT', {
                    count: notificationsCount
                  })}
                                            </p>
                                            <p>
                                                {t('NOTIFICATIONS.NOTIFICATIONS_CHECK')}
                                            </p>
                                            <div className="w-full flex mt-3">
                                                <Button variant="outline" className="ml-auto rounded-xs" onClick={() => {
                    setReadAll(true);
                  }}>
                                                    {t('NOTIFICATIONS.READ_ALL')}
                                                </Button>
                                            </div>
                                        </div> : null}
                                    <LoaderContainer isLoading={isFetching}>
                                        <ul id="notifications" className="flex flex-col">
                                            {notificationItems}
                                        </ul>
                                    </LoaderContainer>
                                </> : <div className="text-center">
                                    <Image className="w-full pointer-events-none" src={emptyIcon?.src} width={100} height={100} alt="empty" />
                                    <h4 className="text-lg font-semibold mb-6">
                                        {t('NOTIFICATIONS.EMPTY_NOTIFICATIONS')}
                                    </h4>
                                    <p className="text-base">
                                        {t('NOTIFICATIONS.NOTIFY_IF_IMPORTANT')}
                                    </p>
                                </div>}
                        </LoaderContainer>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>;
};
export default Notifications;