export const REGISTRATION_TOKEN_STEP_2 = 'regToken-2'; // used for sending step 2 code and to determine step progress
export const REGISTRATION_TOKEN_STEP_3 = 'regToken-3'; // used for sending step 3 code and to determine step progress
export const REGISTRATION_EMAIL = 'regEmail';
export const REGISTRATION_COUNTRY_CODE = 'regCountryCode';
export const REGISTRATION_PHONE = 'regPhone';
export const REGISTRATION_RESEND_VERIFY = 'regToken-2-resend'; // used only for resending verify code
export const COOKIE_SESSION_DATA = 'sessionToken'; // holds base64 encoded session token
export const COOKIE_ACCESS_TOKEN = 'accessToken'; // holds the access token for requests
export const COOKIE_REFRESH_TOKEN = 'refreshToken'; // holds the refresh token]
export const COOKIE_PROFILE_TYPE = 'profile';
export const COOKIE_REMEMBER_USER_PROFILE = 'remToken'; // cookie for rememberMe
export const COOKIE_NEW_REGISTERED_SUPPLIER_ID = 'newRegSupplierId'; // cookie for new registered suppliers - it's only because auth problems
