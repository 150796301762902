import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import useAuthSession from '@/hooks/use-auth-session';
const AuthContext = createContext<ReturnType<typeof useAuthSession> | undefined>(undefined);
export const AuthProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const auth = useAuthSession();
  useEffect(() => {
    const intervalId = setInterval(auth.checkLoginStatus, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [auth.checkLoginStatus]);
  return <AuthContext.Provider value={auth} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth.tsx">{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};