import Image from 'next/image';
import Link from 'next/link';
import { quickLinkRoutes } from '@/routes/public.routes';
import { useTranslation } from 'react-i18next';
import whiteLogoSrc from '../../public/white-logo.svg';
import useIsMobile from '@/hooks/use-is-mobile';
import { BREAKPOINTS } from '@/utils/constants/base';
const Footer = () => {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile(BREAKPOINTS.smLarge);
  const year = new Date().getFullYear();
  return <footer className="min-h-[343px] bg-text flex flex-col items-center justify-around" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
            <div className="w-full max-w-screen-2xl pt-10 md:pb-10 px-6 2xl:px-6">
                <div className="w-full flex flex-col items-center md:flex-row md:items-start md:gap-16 lg:justify-between">
                    <div className="w-full flex flex-col items-center justify-around md:items-start md:justify-start md:w-[254px] pb-6">
                        <Link href="/" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                            <Image src={whiteLogoSrc} alt="ofertirai.me" width={216} height={38} priority data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
                        </Link>
                        <p className="text-white text-left text-sm font-light pt-2" suppressHydrationWarning>
                            {t('FOOTER.OFERTIRAI')}
                        </p>
                        <div className="mr-auto">
                            <h3 className="font-bold text-lg text-white mt-4" suppressHydrationWarning>
                                {t('FOOTER.QUICK_LINKS')}
                            </h3>
                            <Link href="/terms-conditions" className="font-light text-sm" suppressHydrationWarning data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                                {t('FOOTER.TERMS_LINK')}
                            </Link>
                        </div>
                    </div>
                    {isMobile ? <div className="w-full flex justify-around mb-6">
                            <div className="flex gap-8">
                                <a href="https://www.facebook.com/share/2qa1uLD5iymN5yit/" target="_blank" className="text-text hover:text-blue-500 duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-facebook" />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ofertirai.me" target="_blank" className="text-text hover:text-amber-400 duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-instagram" />
                                    </svg>
                                </a>
                                <a href="https://x.com/Ofertirai_Me" target="_blank" className="text-text hover:text-black duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-x" />
                                    </svg>
                                </a>
                            </div>
                        </div> : ''}
                    <div className="w-auto pb-6 text-white">
                        <div className="flex items-center justify-around">
                            <ul className="p-0 flex gap-4">
                                {quickLinkRoutes.map(link => <li key={link.id} className="font-light text-sm pb-1">
                                        <Link key={link.id} href={link.url} className="font-light no-underline text-white" suppressHydrationWarning>
                                            {t(link.name)}
                                        </Link>
                                    </li>)}
                            </ul>
                        </div>
                        <div className="pt-8 flex items-center gap-4"></div>
                    </div>
                    {isMobile ? '' : <div className="w-full md:w-[254px] pb-6 text-white flex justify-around md:justify-end">
                            <div className="w-full flex gap-4 md:w-auto">
                                <a href="https://www.facebook.com/share/2qa1uLD5iymN5yit/" target="_blank" className="text-text hover:text-blue-500 duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-facebook" />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ofertirai.me" target="_blank" className="text-text hover:text-amber-400 duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-instagram" />
                                    </svg>
                                </a>
                                <a href="https://x.com/Ofertirai_Me" target="_blank" className="text-text hover:text-black duration-300 bg-gray-200 p-[6px] rounded-[5px] hover:bg-gray-100">
                                    <svg className="h-5 w-5 fill-current">
                                        <use xlinkHref="/social-icons.svg#icon-x" />
                                    </svg>
                                </a>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="w-full md:border-t-[1px] border-t-[#E4E7E9] h-[60px] flex justify-around items-center">
                <div className="text-white text-sm font-light" suppressHydrationWarning>
                    {t('FOOTER.COPYRIGHT')}&nbsp;&copy;&nbsp;{year}&nbsp;
                    {t('FOOTER.COPYRIGHT_TEXT')}
                </div>
            </div>
        </footer>;
};
export default Footer;