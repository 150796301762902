import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.css';
import useIsMobile from '@/hooks/use-is-mobile';
import { useQueryClient } from '@tanstack/react-query';
import { ServicesQueryKeys } from '@/features/services/api/query-keys';
const languages = [{
  code: 'en',
  name: 'English',
  flag: '/images/flags/en.svg'
}, {
  code: 'bg',
  name: 'Български',
  flag: '/images/flags/bg.svg'
}
// Add more languages here
];
const LanguageSwitcher: React.FC = () => {
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => languages.find(lang => lang.code === router.locale) || languages[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleChangeLanguage = (lang: (typeof languages)[0]) => {
    const {
      pathname,
      asPath,
      query
    } = router;
    router.push({
      pathname,
      query
    }, asPath, {
      locale: lang.code
    });
    setCurrentLanguage(lang);
    setIsOpen(false);
    queryClient.invalidateQueries([ServicesQueryKeys.All] as any);
  };
  useEffect(() => {
    const newLang = languages.find(lang => lang.code === router.locale);
    if (newLang && newLang.code !== currentLanguage.code) {
      setCurrentLanguage(newLang);
    }
  }, [router.locale, currentLanguage.code]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return isMobile ? <div role="menu" data-sentry-component="LanguageSwitcher" data-sentry-source-file="index.tsx">
            {/* <div className="text-lg cursor-pointer uppercase mb-0 no-underline py-2 flex items-center justify-between w-full">
             Select language
             </div> */}
            {languages.map(lang => <a key={lang.code} onClick={() => handleChangeLanguage(lang)} className="text-lg cursor-pointer uppercase mb-2 no-underline py-2 text-border border-border border-b-2 flex items-center justify-between w-full" role="menuitem" tabIndex={0} onKeyDown={e => e.key === 'Enter' && handleChangeLanguage(lang)}>
                    <span className="text-text">{lang.name}</span>
                    <Image src={lang.flag} alt="" width={24} height={24} />
                </a>)}
        </div> : <div className={styles.languageSwitcher} ref={dropdownRef} data-sentry-component="LanguageSwitcher" data-sentry-source-file="index.tsx">
            <button onClick={() => setIsOpen(!isOpen)} aria-haspopup="true" aria-expanded={isOpen}>
                <Image src={currentLanguage.flag} alt={currentLanguage.name} width={24} height={24} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                <span>{currentLanguage.name}</span>
                <Image src="/images/header/dropdown.svg" alt="dropdown" width={30} height={37} priority data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </button>
            {isOpen && <ul className={styles.dropdownMenu} role="menu">
                    {languages.map(lang => <li key={lang.code} onClick={() => handleChangeLanguage(lang)} role="menuitem" tabIndex={0} onKeyPress={e => e.key === 'Enter' && handleChangeLanguage(lang)}>
                            <Image src={lang.flag} alt="" width={24} height={24} />
                            <span>{lang.name}</span>
                        </li>)}
                </ul>}
        </div>;
};
export default LanguageSwitcher;