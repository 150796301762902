import {
    COOKIE_ACCESS_TOKEN,
    COOKIE_PROFILE_TYPE,
    COOKIE_REFRESH_TOKEN,
    COOKIE_SESSION_DATA,
} from '../utils/constants';
import Cookies from 'js-cookie';

export const invalidateCookies = () => {
    Cookies.remove(COOKIE_SESSION_DATA);
    Cookies.remove(COOKIE_REFRESH_TOKEN);
    Cookies.remove(COOKIE_ACCESS_TOKEN);
    Cookies.remove(COOKIE_PROFILE_TYPE);
};
