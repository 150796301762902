import { Briefcase, ChartLine, ChartPie, House, LayoutDashboard, Settings, Wallet, CalendarCheck, Store } from 'lucide-react';
export const supplierCabinetRoutes = [{
  name: 'SUPPLIER_MENU.DASHBOARD',
  icon: <House size={24} stroke="#545756" />,
  path: '/supplier/dashboard'
}, {
  name: 'SUPPLIER_MENU.SUPPLIES_WORKSPACE',
  icon: <LayoutDashboard size={24} stroke="#545756" />,
  path: '/supplier/workspace',
  children: [{
    name: 'SUPPLIER_MENU.WORKSPACE_UPCOMING',
    icon: <CalendarCheck size={24} stroke="#545756" />,
    path: '/supplier/workspace/orders'
  }, {
    name: 'SUPPLIER_MENU.WORKSPACE_HISTORY',
    icon: <Store size={24} stroke="#545756" />,
    path: '/supplier/workspace/orders/history'
  }]
}, {
  name: 'SUPPLIER_MENU.PERSONAL_INFORMATION',
  icon: <ChartPie size={24} stroke="#545756" />,
  path: '/supplier/information'
}, {
  name: 'SUPPLIER_MENU.PROFESSIONAL_PROFILE',
  icon: <ChartLine size={24} stroke="#545756" />,
  path: '/supplier/professional-profile'
},
// {
//   name: "SUPPLIER_MENU.MY_CALENDAR",
//   icon: <Wallet size={24} stroke="#545756" />,
//   path: "/supplier/my-calendar",
//   disabled: true,
// },
// {
//   name: "SUPPLIER_MENU.HELP",
//   icon: <Briefcase size={24} stroke="#545756" />,
//   path: "/supplier/help",
//   disabled: true,
// },
{
  name: 'SUPPLIER_MENU.SETTINGS',
  icon: <Settings size={24} stroke="#545756" />,
  path: '/supplier/settings',
  disabled: false
}];