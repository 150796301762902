import '@/styles/global.css';
import { Roboto } from 'next/font/google';
import type { AppProps } from 'next/app';
import { appWithTranslation, UserConfig } from 'next-i18next';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import ReactQueryProvider from '@/utils/providers/react-query-provider';
import PublicLayout from '@/layouts/public';
import nextI18NextConfig from '../../next-i18next.config.js';
import ProtectedLayout from '@/layouts/protected';
import { AuthProvider } from '@/features/auth/context/auth';
import { GoogleAnalytics } from '@next/third-parties/google';
import { AvatarProvider } from '@/features/files/context/avatar';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
const emptyInitialI18NextConfig: UserConfig = {
  i18n: {
    defaultLocale: nextI18NextConfig.i18n.defaultLocale,
    locales: nextI18NextConfig.i18n.locales
  }
};
const roboto = Roboto({
  weight: '400',
  subsets: ['latin']
});
function Ofertirai({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}: AppProps) {
  console.info('Current app version: ', process.env.NEXT_PUBLIC_APP_VERSION);
  const router = useRouter();
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_META_PIXEL && process.env.NODE_ENV !== 'development') {
      console.log('init pixel');
      import('react-facebook-pixel').then(x => x.default).then(ReactPixel => {
        ReactPixel.init(process.env.NEXT_PUBLIC_META_PIXEL || '');
        ReactPixel.pageView();
        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
    }
  }, [router.events]);
  return <ReactQueryProvider data-sentry-element="ReactQueryProvider" data-sentry-component="Ofertirai" data-sentry-source-file="_app.tsx">
            <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
                <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
                    <AvatarProvider data-sentry-element="AvatarProvider" data-sentry-source-file="_app.tsx">
                        <Script src="//code.jivosite.com/widget/N7F0SFVJiR" async data-sentry-element="Script" data-sentry-source-file="_app.tsx" />

                        {(Component as any).isPrivatePage ? <ProtectedLayout title={(Component as any).title}>
                                <style jsx global>{`
                                    html {
                                        font-family: ${roboto.style.fontFamily};
                                    }
                                `}</style>
                                <GoogleAnalytics gaId="G-NM8GY3MZK0" />
                                <Component {...pageProps} />
                            </ProtectedLayout> : <PublicLayout>
                                <style jsx global>{`
                                    html {
                                        font-family: ${roboto.style.fontFamily};
                                    }
                                `}</style>
                                <GoogleAnalytics gaId="G-NM8GY3MZK0" />
                                <Component {...pageProps} />
                            </PublicLayout>}
                    </AvatarProvider>
                </AuthProvider>
            </Provider>
        </ReactQueryProvider>;
}
export default appWithTranslation(Ofertirai, emptyInitialI18NextConfig);