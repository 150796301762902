module.exports = {
    debug: process.env.NODE_ENV === 'development',
    i18n: {
        locales: ['bg', 'en'],
        defaultLocale: 'bg',
        localeDetection: false,
    },
    react: { useSuspense: false },
    productionBrowserSourceMaps: true,
};
