import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state type
interface CategoryState {
    categoryId: number;
}

const initialState: CategoryState = {
    categoryId: 0,
};

const homePageSelectCategory = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setCategoryId: (state, action: PayloadAction<number>) => {
            state.categoryId = action.payload;
        },
        removeCategoryId: (state) => {
            state.categoryId = 0;
        },
    },
});

export const { setCategoryId, removeCategoryId } =
    homePageSelectCategory.actions;

export default homePageSelectCategory.reducer;
