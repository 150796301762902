import { captureException } from '@sentry/nextjs';

export const Logger = {
    captureException: (error: any) => {
        if (process.env.NODE_ENV === 'production') {
            captureException(error);
        } else {
            console.error(error);
        }
    },
};
